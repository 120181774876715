export const seoConfig = {
    title: "maaboom ศูนย์รวมร้านค้าสำหรับสัตว์เลี้ยงที่น่ารักของคุณ",
    titleTemplate: "%s",
    defaultTitle: "maaboom",
    description: "maaboom ศูนย์รวมร้านค้าสำหรับสัตว์เลี้ยงที่น่ารักของคุณ",
    canonical: "",
    openGraph: {
      type: "website",
      locale: "th_TH",
      url: "",
      site_name: "maaboom",
      title: "maaboom ศูนย์รวมร้านค้าสำหรับสัตว์เลี้ยงที่น่ารักของคุณ",
      description: "maaboom ศูนย์รวมร้านค้าสำหรับสัตว์เลี้ยงที่น่ารักของคุณ",
      images: [
        {
          url: "https://cdn.maaboom.com/web/fb_share.png",
          width: 1200,
          height: 630,
          alt: "maaboom.com",
          type: "image/png",
          secureUrl: "https://cdn.maaboom.com/web/fb_share.png",
        },
      ],
    },
    robots: {
      isProduction: {
        name: "robots",
        content: "all",
      },
      isNotProduction: {
        name: "robots",
        content: "noindex, nofollow, noarchive, nosnippet, notranslate, noimageindex, unavailable_after",
      },
    },
    additionalMetaTags: [
      {
        property: "color-scheme",
        content: "light only",
      },
      {
        name: "keywords",
        content:
          "Shop, marketplace, ecommerce, สัตว์เลี้ยง, น้องหมา, น้องแมว, Pet, Pets, maaboom, Community, สัตวแพทย์, หมา, แมว, ฝากสัตว์เลี้ยง, โรงแรม ฟาร์มแมว ฟาร์มหมา",
      },
      {
        httpEquiv: "x-ua-compatible",
        content: "IE=edge; chrome=1",
      },
      {
        name: "twitter:title",
        content: "maaboom ศูนย์รวมร้านค้าสำหรับสัตว์เลี้ยงที่น่ารักของคุณ",
      },
      {
        name: "twitter:description",
        content: "maaboom ศูนย์รวมร้านค้าสำหรับสัตว์เลี้ยงที่น่ารักของคุณ",
      },
      {
        name: "twitter:image",
        content: "https://cdn.maaboom.com/web/fb_share.png",
      },
    ],
    twitter: {
      handle: "@maaboom", // Replace with Maaboom's Twitter handle
      site: "@maaboom",   // Replace with Maaboom's Twitter site handle
      cardType: "summary_large_image", // This can remain the same
    },
    facebook: {
      appId: "1107205800174708",
    },
    socialLinks: [
      "https://www.facebook.com/maaboom.official",
      "https://www.instagram.com/maaboom.official/",
      "https://www.youtube.com/@maaboom-club",
      "https://www.tiktok.com/@maaboom",
      "https://play.google.com/store/apps/details?id=com.petsploy360innovative.petsploy",
      "https://apps.apple.com/th/app/maaboom/id1662787944"
    ],
    address: {
      "@type": "PostalAddress",
      "streetAddress": "111/1, Building 111 Praditmanutham, Unit B210, Praditmanutham Rd, Ladprao",
      "addressLocality": "Bangkok",
      "addressCountry": "TH",
      "addressRegion": "Thailand",
      "postalCode": "10230"
    },
  };
  