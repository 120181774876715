import axios from "axios";
import { Config } from "../config";

export const ProductInCart = async (token: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `cart/get-product-in-cart`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
   
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};

export const ItemInCart = async (token: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `cart/get-item-in-cart`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
   
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};


export const ProductAddCart = async (token: string, dataProduct: any) => {
  // console.log(dataSelect);

  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `cart/add`,
      headers: {
        "accept-language": "th",
        "x-access-token": token,
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json"
      },
      data: dataProduct
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
}

// export const ProductAddCart = async (data: string, token: string) => {
//   try {
//     const data = await axios({
//       method: "post",
//       maxBodyLength: Infinity,
//       // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
//       url: Config.apiEndpoint + `cart/add`,
//       headers: {
//         "accept-language": "th",
//         "Authorization": "Basic " + Config.encoded,
//         //  'Authorization': 'Basic Y2VsZWJyaW1ib3I6Z1FqanRKVVRsVkVvU0I0N3VTUWI=',
//         "x-access-token": token,
//         "Content-Type": "application/json",
//       },
//     });
//     data : data;
//   } catch (error) {
//     // if (axios.isAxiosError(error) && error.response) {
//     //   error = error.response;
//     //   return error;
//     // }
//   }
// };

export const ProductRemoveCart = async (data: string, token: string) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      url: Config.apiEndpoint + `cart/delete`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    data: data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const GetSummaryOrder = async (token: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `cart/get-summary-order`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });

    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};

export const GetSummaryOrderMini = async (token: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `cart/get-summary-order-mini`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};

export const UpdateShipmentInOrders = async (getToken: string, orderId: number | string, shipment: string) => {

  let dataBody = JSON.stringify({
    shipment: shipment.toString(),
    order_ids: orderId.toString(),
  });

  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `order/update-shipment-in-orders`,
      headers: {
        "accept-language": "th",
        "x-access-token": getToken,
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json"
      },
      data: dataBody
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
}


export const updateAddressInOrders = async (getToken: string, addressId: number, orderId: string | number) => {

  let dataBody = JSON.stringify({
    address_id: addressId,
    order_ids: orderId.toString(),
  });

  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `order/update-address-in-orders`,
      headers: {
        "accept-language": "th",
        "x-access-token": getToken,
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json"
      },
      data: dataBody
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};

export const updateTaxAddressInOrders = async (getToken: string, orderId: string, tax_type: number, tax_id: number) => {

  let dataBody = JSON.stringify({
    order_ids: orderId.toString(),
    tax_type: tax_type,
    tax_id: tax_id
  });

  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `order/update-tax-in-orders`,
      headers: {
        "accept-language": "th",
        "x-access-token": getToken,
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json"
      },
      data: dataBody
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};


export const calShipPop = async (getToken: string, orderId: number, shipmentAll: string) => {

  let dataBody = JSON.stringify({
    order_ids: orderId.toString(),
    courier_codes: shipmentAll.toString(),
  });

  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `shippop/cal-shippop`,
      headers: {
        "accept-language": "th",
        "x-access-token": getToken,
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json"
      },
      data: dataBody
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
}
