import React, { useState } from 'react'
import {
    ListItem,
    ListItemText,
    Avatar,
    ListItemAvatar,
    Paper,
    List,
    Badge,
    Box,
} from '@mui/material';
import LastSeen from './../../../components/chats/LastSeen';
import { useSelector, useDispatch } from 'react-redux'
import {    
    addBadgeTotal,
    doJoinRoom,
    getRoom,
    getRooms,
    loadMessages,
    sideBarChatActiveHandle,
    updateRoom
} from '../../../store/slices/chatSlice'
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import _ from 'lodash';

interface Room {
    roomImage: string
    lastMessage: string
    lastMessageDate: string
    roomId: string
    roomName: string
    participateId: string
    ownerId: string
    isOnline: string
    totalUnread: number
}


interface Props {
    responsiveGoBack: (status : boolean) => void;
}

const SidebarBuyer = (props: Props) => {     
    const [isSideBarChatActive, setSideBarChatActive] = useState<string | null>(null)
    const dispatch = useDispatch();
    const actionJoinRoom = useSelector(doJoinRoom)
    const room = useSelector(getRoom) as Room
    const rooms = useSelector(getRooms) as Room[];    
    
    const sideBarChat :any = useSelector(sideBarChatActiveHandle);    
    const prevRoomIdActive = sideBarChat.payload.chat.sideBarChatActive.roomId;
    React.useEffect(() => {

        if (actionJoinRoom?.participateId) {
            const room = _.find(rooms, item => `${item.participateId}` == `s${actionJoinRoom?.participateId}`.padStart(24, "0"))
            if (room) {
                setTimeout(() => {
                    const newLoad = true
                    click(room, newLoad)
                }, 1000)
            }
        }
    }, [actionJoinRoom?.participateId])


    const click = (_room: any, newLoad = false) => {     
        //set active when click on chat room
        setSideBarChatActive(_room.roomId);  
        
        props.responsiveGoBack(false);

        //click room at sidebar only allowed at once
        // if (_room.roomId == room.roomId && newLoad == false) {
        //     return;
        // }

        dispatch(loadMessages(_room))
        dispatch(updateRoom({
            status: 'updateRoomBadge',
            roomId: _room.roomId,
            totalUnread: 0
        }))
        dispatch(addBadgeTotal({
            num: _room.totalUnread,
            act: 'minus'
        }))

        //store room for set sidebar active
        dispatch(sideBarChatActiveHandle({
            roomId: _room.roomId,            
        }));
    

    }
    const isRoomsEmpty = !rooms || Object.keys(rooms).length === 0;
    return (
        <>
            {!isRoomsEmpty ? (
            <Paper elevation={0} style={{ height: '93vh', overflow: 'auto' }}>   
                <List>
                    {
                        
                        rooms.map(_room => {
                            return (
                                <ListItem 
                                    sx={{
                                        bgcolor: (isSideBarChatActive === _room.roomId || prevRoomIdActive === _room.roomId) ? '#dee2e6' : '',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => click(_room)} key={_room.roomId}>
                                    <ListItemAvatar>
                                        <Avatar src={_room?.roomImage} ></Avatar>
                                        {
                                            _room?.isOnline === '1' ?
                                                <FiberManualRecord style={{
                                                    position: 'absolute',
                                                    top: 10,
                                                    right: 10,
                                                    color: '#18c518',
                                                    width: 10,
                                                    height: 10
                                                }} />
                                                :
                                                <FiberManualRecord style={{
                                                    position: 'absolute',
                                                    top: 10,
                                                    right: 10,
                                                    color: 'red',
                                                    width: 10,
                                                    height: 10
                                                }} />
                                        }
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={_room?.roomName}
                                        primaryTypographyProps={{
                                            fontSize: 13
                                        }}
                                        secondary={<span 
                                            style={{ 
                                                fontSize: 12, color: '#838383',
                                                whiteSpace: 'nowrap', 
                                                overflow: 'hidden', 
                                                textOverflow: 'ellipsis',
                                                marginRight:'15px',
                                                display:'inherit'
                                            }}
                                            >{_room.lastMessage}</span>}

                                    />
                                    <LastSeen>{_room?.lastMessageDate}</LastSeen>
                                    <Badge color="error" badgeContent={_room?.totalUnread}></Badge>
                                </ListItem>
                            )
                        })
                    }

                </List>
            </Paper>
            ) : (
                <Box
                    sx={{ minHeight: { xs: '230px', md: '300px' } }}
                    style={{
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                    <div>ไม่พบการสนทนา</div>
                </Box>
            )}
        </>
    )
}

export default SidebarBuyer