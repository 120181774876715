import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import _, { findIndex } from 'lodash'
import moment, { Moment } from 'moment'
import Swal from "sweetalert2";

interface Room {
    roomImage: string
    lastMessage: string
    lastMessageDate: string
    roomId: string
    roomName: string
    participateId: string
    ownerId: string
    isOnline: string
    totalUnread: number
}



interface Message {
    contentType?: string
    createdAt: Moment
    isOwner: boolean
    message: string
    messageId: string
    read: boolean
    attachments?: any
}



interface Props {
    openChatDialog: number,
    totalBadge: number
    lastJoinRoom: {
        participateId?: number
        userType?: string
        productId?: number
        storeId?: number
    }
    pagination: {
        skip: number
        limit: number
        next: number | null
    },
    room: Room | any
    rooms: Room[]
    messages: Message[]
    token: string
    user_type: string
    blockWords: string
}

const initialState = {
    sideBarChatActive: [],
    openChatDialog: 0,
    totalBadge: 0,
    lastJoinRoom: {},
    pagination: {
        skip: 0,
        limit: 20,
        next: null
    },
    room: {},
    rooms: [],
    messages: [],
    token: '',
    user_type: '',
    blockWords: ""
} as Props

const sortRooms = (rooms: Room[], roomId: string, newMessage: string, createdAt: Moment) => {
    const foundIndex = findIndex(rooms, { roomId: roomId })
    if (foundIndex >= 0) {
        rooms[foundIndex].lastMessage = newMessage
        rooms[foundIndex].lastMessageDate = createdAt.toString()
        if (foundIndex > 0) {
            const messageMove = rooms.splice(foundIndex, 1)
            rooms.unshift(messageMove[0])
        }
    }

    return rooms;
}

const groupedDays = (messages: Message[] | any[]) => {
    return messages.reduce((acc, el, i) => {
        const messageDay = moment(el.createdAt).format('YYYY-MM-DD');
        if (acc[messageDay]) {
            return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
        }
        return { ...acc, [messageDay]: [el] };
    }, {});
}

const generateItems = (messages: Message[] | any[]) => {
    const days = groupedDays(messages);

    const sortedDays = Object.keys(days).sort(
        (x, y) => moment(y, 'YYYY-MM-DD').unix() + moment(x, 'YYYY-MM-DD').unix()
    );
    const items = sortedDays.reduce((acc, date) => {
        const sortedMessages = days[date].sort(
            (x: Message, y: Message) => moment(y.createdAt).unix() + moment(x.createdAt).unix()
        );
        return acc.concat([{ type: 'day', date, id: date }, ...sortedMessages]);
    }, []);
    return items;
}

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        reset: () => initialState,
        openChatDialog:  (state, action: PayloadAction<any>) => {                
            // state.openChatDialog = action.payload.openUnixDateTime;
            state.openChatDialog = action.payload.openChatDialog;
        },
        setToken: (state, action: PayloadAction<any>) => {
            state.token = action.payload.token
            state.user_type = action.payload.user_type
        },
        uploadFile: (state, action: PayloadAction<any>) => {
            const createdAt = moment()
            const attachments = action.payload.attachments

            state.messages = [...state.messages, {
                createdAt: createdAt,
                contentType: action.payload.content.contentType,
                isOwner: true,
                read: false,
                message: '',
                attachments: Array.from(attachments).map((file: File | any) => {
                    return {
                        fileName: file.name,
                        fileSize: file.size,
                        fullPath: action.payload.content.contentType == 'photo' ? URL.createObjectURL(file) : URL.createObjectURL(file)
                    }
                }),
                messageId: ""
            }]
            state.rooms = sortRooms(state.rooms, state.room.roomId, 'Send Attachment', createdAt);
        },
        reConnectSocket: (state, action: PayloadAction<any>) => {

        },
        updateBlockWords: (state, action: PayloadAction<any>) => {
            state.blockWords = action.payload.blockWords
        },
        joinRoom: (state, action: PayloadAction<any>) => {
            state.lastJoinRoom = {
                participateId: action.payload.participateId,
                userType: action.payload.userType,
                productId: action.payload.productId,
                storeId: action.payload.storeId
            }
        },
        loadMessages: (state, action: PayloadAction<any>) => {
            state.room = action.payload;            
        },     
        sendMessage: (state, action: PayloadAction<any>) => {
            const createdAt = moment()
            const newMessage = action.payload.content.message


            state.messages = [...state.messages, {
                createdAt: createdAt,
                contentType: 'text',
                isOwner: true,
                read: false,
                message: newMessage,
                messageId: "",
                ...action.payload.content
            }]
            state.rooms = sortRooms(state.rooms, state.room.roomId, newMessage, createdAt);
        },
        updateReadMessage: (state, action: PayloadAction<any>) => {
            const _messages = JSON.parse(JSON.stringify(current(state).messages));
            state.messages = _messages.map((item: any) => {
                if (!item.read) {
                    item.read = true;
                }
                return item
            })
        },
        searchRooms: (state, action: PayloadAction<any>) => {
            // state.rooms = action.payload
        },
        addRooms: (state, action: PayloadAction<any>) => {                        
            state.rooms = action.payload;
            
            let totalUnreadCount = action.payload.reduce((sum: number, item: any) => {
                return sum + (item.totalUnread || 0); // Add totalUnread only if it exists, otherwise add 0
            }, 0);
            state.totalBadge = totalUnreadCount;
        },
        addMessages: (state, action: PayloadAction<any>) => {
            state.pagination = action.payload.pagination
            if (action.payload.pagination.skip > 0) {
                state.messages = [...generateItems(action.payload.messages), ...state.messages]
            } else {
                state.messages = generateItems(action.payload.messages)
            }

        },
        updateMessage: (state, action: PayloadAction<any>) => {
            state.messages = [...state.messages, action.payload.message]
        },
        //update room data
        updateRoom: (state, action: PayloadAction<any>) => {    
            if (action.payload?.status == 'createRoom') {
                // state.room = action.payload;
                state.rooms.push(action.payload)
            } else if (action.payload?.status == 'updateRoomBadge') { 
                state.rooms = state.rooms.map(room => {
                    if (room.roomId == action.payload?.roomId) {
                        room.totalUnread = action.payload?.totalUnread;                     
                    }
                    return room
                })
            } else {
                let chatUnreadCountTotalBadge = state.totalBadge + 1; 
                state.rooms = sortRooms(state.rooms, action.payload.roomId, action.payload.message, action.payload.createdAt);            
                state.totalBadge = chatUnreadCountTotalBadge;

                //set total unread for show in sidebar room
                state.rooms.map(room => {                                       
                    if (room.roomId == action.payload?.roomId) {
                        let initRoomUnreadCount = action.payload.roomUnreadCount;
                        if (room.totalUnread){
                            let chatUnreadRoomByRoom = room.totalUnread+1;  
                            room.totalUnread = chatUnreadRoomByRoom;
                        }else{// for first init room unread count
                            room.totalUnread = initRoomUnreadCount+1;
                        }
                    }
                });
            }
        },
        updateRoomStatus: (state, action: PayloadAction<any>) => {

            state.rooms = state.rooms.map(item => {
                if (item.participateId == action.payload.userId) {
                    item.isOnline = action.payload.isOnline
                }
                return item
            })
            state.room.isOnline = action.payload.isOnline
        },
        eventError: (state, action: PayloadAction<any>) => {
            state.messages.pop()
            state.messages = state.messages
        },
        //calculate total message of chat in badge
        addBadgeTotal: (state, action: PayloadAction<any>) => {
            if (action.payload.act == 'total') {
                const badge = action.payload.num
                state.totalBadge = _.isNaN(badge) ? badge : 0
            } else if (action.payload.act == 'add') {
                const total = state.totalBadge + action.payload.num
                const badge =  (total >= 99 ? '99+' : total)
                state.totalBadge = _.isNaN(badge) ? badge : 0
            } else if (action.payload.act == 'minus') {
                const total = state.totalBadge - action.payload.num
                const badge = total <= 0 ? 0 : total
                state.totalBadge = badge ? badge : 0
            }
        },
        sideBarChatActiveHandle: (state:any, action) => {
            state.sideBarChatActive = action.payload;
        },
        clearAllBadgeTotal: (state, action: PayloadAction<any>)=>{
            state.totalBadge = action.payload.totalUnread;
        }
    },
})

export const { clearAllBadgeTotal, sideBarChatActiveHandle, addRooms, addMessages, loadMessages,  sendMessage, updateMessage, searchRooms, updateRoom, updateReadMessage, reConnectSocket, uploadFile, joinRoom, updateRoomStatus, setToken, updateBlockWords, reset, eventError, addBadgeTotal, openChatDialog } = chatSlice.actions
export const getRooms = (state: any) => state.chat.rooms
export const getMessages = (state: any) => state.chat.messages
export const getRoom = (state: any) => state.chat.room
export const doJoinRoom = (state: any) => state.chat.lastJoinRoom
export const getToken = (state: any) => state.chat.token
export const resetInit = (state: any) => state.chat.reset
export const getBadgeTotal = (state: any) => state.chat.totalBadge
export const getPagination = (state: any) => state.chat.pagination
export const getOpenChatDialog = (state: any) => state.chat.openChatDialog
export default chatSlice.reducer