import axios from "axios";
import { Config } from "../config";
import { ProductDataUpdateShipmentInOrder } from "@/interfaces/product/product-calShipop";

export const ProductInOrder = async (token: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `order/get-order`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const SummaryOrder = async (token: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      url: Config.apiEndpoint + `cart/get-summary-order`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const GetOrderDetail = async (token: string, orderId: string ) => {
  try {
      if (typeof orderId !== 'undefined') {
         const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `order/get-order-detail/${orderId}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;

      }else{

      }
   
  } catch (error) {
   
  }
};

export const ProductAddCart = async (data: string, token: string) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      url: Config.apiEndpoint + `cart/add`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    data : data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProductRemoveCart = async (data: string, token: string) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      url: Config.apiEndpoint + `cart/delete`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    data : data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const OrderAddress = async (token: string, addressId: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      url: Config.apiEndpoint + `address/get-delivery-address/${addressId}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const OrderAddressAll = async (token: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      url: Config.apiEndpoint + `address/get-list-delivery-address`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};


export const TaxAddress = async (token: string, taxAddressId: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      url: Config.apiEndpoint + `address/get-tax-address/${taxAddressId}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const TaxAddressAll = async (token: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      url: Config.apiEndpoint + `address/get-list-tax-address`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};


export const UpdateShipmentInorder = async (token: string,dataForm:ProductDataUpdateShipmentInOrder) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      url: Config.apiEndpoint + `order/update-shipment-in-orders`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
      data:dataForm
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};




export const GetCancleOrder = async (token: string, orderId: string) => {
  try {
    const data = await axios({
      method: "delete",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `order/${orderId}/cancel-order/`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
  }
};

export const GetCancleOrderReasonOption = async (token: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `order/get-cancel-order-reason-option`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
  }
};




export const GetOrderByStatus = async (token: string, order_status:any) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
     url: Config.apiEndpoint + `order/get-order-by-status?order_status=${order_status}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
  }
};


export const GetReturnRefundsOrderDetail = async (token: string, buyer:any, order_id:any) => {
  try {

    if (typeof order_id !== 'undefined') {
      const data = await axios({
        method: "get",
        maxBodyLength: Infinity,
        url: Config.apiEndpoint + `order/get-return-refund-order-detail/?type=${buyer}&order_id=${order_id}`,
        headers: {
          "accept-language": "th",
          "Authorization": "Basic " + Config.encoded,
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });
      return data.data;
    } else {
     
      // console.log("order_id is undefined, skipping API call");
    }
  } catch (error) {

    console.error("Error:", error);
  }
};




export const GetOrderTracking = async (token: string, tracking_code:any) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
     url: Config.apiEndpoint + `order/get-order-tracking?tracking_code=${tracking_code}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
  }
};


