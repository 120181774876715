export const Config = {
  appName: process.env.NEXT_PUBLIC_APP_NAME,
  appPassword: process.env.NEXT_PUBLIC_APP_PASSWORD,
  apiEndpoint: process.env.NEXT_PUBLIC_APP_DEV_API_ENDPOINT,
  encoded: Buffer.from(
    process.env.NEXT_PUBLIC_APP_NAME +
    ":" +
    process.env.NEXT_PUBLIC_APP_PASSWORD,
    "utf8"
  ).toString("base64"),
  // publicKeyOmise: process.env.NEXT_PUBLIC_APP_OMISE_PUBLIC_KEY,
  apiChatEndpoint: process.env.NEXT_PUBLIC_APP_CHAT_API,
  apiCampaignEndpoint: process.env.NEXT_PUBLIC_APP_DEV_CAMPAIGN,
  apiSellerEndpoint: process.env.NEXT_PUBLIC_APP_DEV_SELLER || "",
  checkEnv: process.env.NEXT_PUBLIC_APP_ENV,
  petsPloy: process.env.NEXT_PUBLIC_APP_ENV_PETSPLOY,
  maaBoom: process.env.NEXT_PUBLIC_APP_ENV_MAABOOM,
  omise:process.env.NEXT_PUBLIC_OMISE_PUBLIC_KEY
};

export const ConfigMember = {
  appName: process.env.NEXT_PUBLIC_APP_NAME,
  appPassword: process.env.NEXT_PUBLIC_APP_PASSWORD,
  apiEndpoint: process.env.NEXT_PUBLIC_APP_DEV_API_MEMBER_ENDPOINT,
  encoded: Buffer.from(
    process.env.NEXT_PUBLIC_APP_NAME +
    ":" +
    process.env.NEXT_PUBLIC_APP_PASSWORD,
    "utf8"
  ).toString("base64"),
};

export const ImgUrl = {
  pathImg: `${process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL}`,
  img_cdn: `${process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL}uploads/`,
  img_cdn_shop_image: `${process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL}shop/`,
  img_cdn_shop: `${process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL}shop/uploads/`,
  imgArticle: `${process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL}uploads/articles/`,
  imgWriter: `${process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL}uploads/writer/`,
  imgCover: `${process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL}uploads/places/coverimage/`,
  imgLogoPlace: `${process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL}uploads/places/logoimage/`,
  imgLogo: `${process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL}uploads/places_category/logoimage/`,
  imgGallerry: `${process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL}uploads/places/gallery/`,
  imgGallerryUat: `${process.env.NEXT_PUBLIC_APP_ENV_CMS_URL}/uploads/places/gallery`,
  imgVideo: `${process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL}uploads/video/`,
  imageassets: `${process.env.NEXT_PUBLIC_APP_ENV_MAABOOM}`,
  imageCampaign: process.env.NEXT_PUBLIC_APP_DEV_CAMPAIGN_IMAGE,
  imageCMSUrl: process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL,
  imageUrlCenter: process.env.NEXT_PUBLIC_APP_ENV_IMAGE_URL,
};

export const LinkUrl = {
  sellerUrl: process.env.NEXT_PUBLIC_APP_DEV_CAMPAIGN_IMAGE || "",
  helpUrl: 'https://help.maaboom.com/',
}

export const PetsPloysLogoShipping = `${ImgUrl.pathImg}web/logoShipping.png`

export const isProduction = process.env.NEXT_PUBLIC_APP_ENV === "production"

export const isDevelopment = process.env.NEXT_PUBLIC_APP_ENV === "development"
