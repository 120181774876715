import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IAddress, IAddressTax, Order, TotalPrice } from '@/interfaces/checkout';
import * as ApiOrder from "../../api/order";
import * as ApiCart from "../../api/cart";


interface CheckoutState {
    isLoading: boolean;
    isLoadingAddresses: boolean;
    isLoadingTaxAddresses: boolean;
    isLoadingSummaryOrder: boolean;
    isLoadingSummaryOrderMini: boolean;
    isLoadingUpdateShipmen: boolean;
    dataSummaryOrder: TotalPrice;
    dataOrder: Order[];
    dataAddressAll: IAddress[];
    selectedAddress: IAddress | null;
    dataAddressTaxAll: IAddressTax[];
    selectedAddressTax: IAddressTax | null;
    orderIdAll: string;
    isLoadingPayment: boolean;
    alertNotOrder: boolean;
   
}

const initialState: CheckoutState = {
    isLoading: true,
    isLoadingAddresses: false,
    isLoadingTaxAddresses: false,
    isLoadingSummaryOrder: false,
    isLoadingUpdateShipmen: false,
    isLoadingSummaryOrderMini: false,
    dataSummaryOrder: {
        quantity: 0,
        normal_price: 0,
        discounted_price: 0,
        shipment_price: 0,
        net_price: 0,
        coupon_price:0,
    },
    dataOrder: [],
    dataAddressAll: [],
    selectedAddress: null,
    dataAddressTaxAll: [],
    selectedAddressTax: null,
    orderIdAll: "",
    isLoadingPayment: false,
    alertNotOrder: false,
};

export const fetchAddresses = createAsyncThunk('checkout/fetchAddresses', async (authToken: string) => {
    const responseAddress = await ApiOrder.OrderAddressAll(authToken);
    return responseAddress?.data;
});

export const fetchTaxAddresses = createAsyncThunk('checkout/fetchTaxAddresses', async (authToken: string) => {
    const responseAddress = await ApiOrder.TaxAddressAll(authToken);
    return responseAddress?.data;
});

export const fetchSummaryOrder = createAsyncThunk('checkout/fetchSummaryOrder', async (authToken: string) => {
    const summaryRes = await ApiCart.GetSummaryOrder(authToken);
    return summaryRes;
});

export const fetchSummaryOrderMini = createAsyncThunk('checkout/fetchSummaryOrderMini', async (authToken: string) => {
    const summaryResMini = await ApiCart.GetSummaryOrder(authToken);

    
    return summaryResMini;
});



export const updateShipmen = createAsyncThunk('checkout/updateShipmen', async ({ dataOrder, authToken }: { dataOrder: Order[], authToken: string }, { dispatch, getState }) => {
    const state = getState() as { checkout: CheckoutState };
    const { selectedAddress, dataAddressAll, dataAddressTaxAll, orderIdAll } = state.checkout;
  
    for (const order of dataOrder) {
        if (order.tax_id !== 0) {
            let dataAddressTax = dataAddressTaxAll?.find((item: IAddressTax) => item.address_id === order.tax_id);
            if (!dataAddressTax) {
                dataAddressTax = dataAddressTaxAll?.find((item: IAddressTax) => item.is_default === 2);
                if (dataAddressTax) {
                    const tax_type = dataAddressTax.is_company === 2 ? 2 : 1;
                    await ApiCart.updateTaxAddressInOrders(authToken, orderIdAll, tax_type, dataAddressTax.address_id);
                }
            }
            await dispatch(setSelectedAddressTax(dataAddressTax || null));
        }
        if (order.delivery_id !== 0) {
            let dataAddress = dataAddressAll?.find((item: IAddress) => item.address_id === order.delivery_id);
            if (!dataAddress) {
                dataAddress = dataAddressAll?.find((item: IAddress) => item.is_default === 2);
                if (dataAddress) {
                    await ApiCart.updateAddressInOrders(authToken, dataAddress.address_id, order.order_id);
                }
            }
            await dispatch(setSelectedAddress(dataAddress || null));
        }
        if (order.delivery_id !== 0 && order.shipment === '0') {
            await ApiCart.UpdateShipmentInOrders(authToken, order.order_id, order.shipment_courier[0].courier_code);
        } else if (order.delivery_id == 0 && order.shipment === '0') {
            if (selectedAddress) {
                if (order.shipment_courier_status) {
                    await ApiCart.updateAddressInOrders(authToken, selectedAddress.address_id, order.order_id);
                    await ApiCart.UpdateShipmentInOrders(authToken, order.order_id, order.shipment_courier[0].courier_code);
                }
            }
        }
    }
    await dispatch(fetchSummaryOrderMini(authToken));
    await dispatch(fetchSummaryOrder(authToken));
});

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setSelectedAddress(state, action: PayloadAction<IAddress | null>) {
            state.selectedAddress = action.payload;
        },
        setSelectedAddressTax(state, action: PayloadAction<IAddressTax | null>) {
            state.selectedAddressTax = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setLoadingSummaryOrder(state, action: PayloadAction<boolean>) {
            state.isLoadingSummaryOrder = action.payload;
        },
        setLoadingPayment(state, action: PayloadAction<boolean>) {
            state.isLoadingPayment = action.payload;
        },
        resetState(state) {
            Object.assign(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAddresses.pending, (state) => {
                state.isLoadingAddresses = true;
            })
            .addCase(fetchAddresses.fulfilled, (state, action) => {
                if (action.payload.length == 0) {
                    state.isLoading = false
                    state.isLoadingAddresses = false;
                } else {
                    state.dataAddressAll = action.payload;
                    const defaultAddress = action.payload?.find((item: IAddress) => item.is_default === 2);
                    state.selectedAddress = defaultAddress || null;
                    state.isLoadingAddresses = false;
                }

            })
            .addCase(fetchAddresses.rejected, (state) => {
                state.isLoadingAddresses = false;
            })
            .addCase(fetchTaxAddresses.pending, (state) => {
                state.isLoadingTaxAddresses = true;
            })
            .addCase(fetchTaxAddresses.fulfilled, (state, action) => {
                state.dataAddressTaxAll = action.payload;
                const defaultAddressTax = action.payload?.find((item: IAddressTax) => item.is_default === 2);
                state.selectedAddressTax = defaultAddressTax || null;
                state.isLoadingTaxAddresses = false;
            })
            .addCase(fetchTaxAddresses.rejected, (state) => {
                state.isLoadingTaxAddresses = false;
            })
            .addCase(fetchSummaryOrder.pending, (state) => {
                state.isLoadingSummaryOrder = true;
            })
            .addCase(fetchSummaryOrder.fulfilled, (state, action) => {
                if (action.payload) {
                    if (action.payload.data[1].Order.length == 0) {
                        state.alertNotOrder = true
                    }
                    state.dataOrder = action.payload.data[1].Order;
                    state.isLoadingSummaryOrder = false;
                    state.orderIdAll = action.payload.data[1].Order.map((order: Order) => order.order_id).join(",");
                }
            })
            .addCase(fetchSummaryOrder.rejected, (state) => {
                state.isLoadingSummaryOrder = false;
            })
            .addCase(fetchSummaryOrderMini.pending, (state) => {
                state.isLoadingSummaryOrderMini = true;
            })
            .addCase(fetchSummaryOrderMini.fulfilled, (state, action) => {
                if (action.payload) {
                    state.dataSummaryOrder = action.payload.data[0].order_summary
                    state.isLoadingSummaryOrderMini = false;
                }
            })
            .addCase(fetchSummaryOrderMini.rejected, (state) => {
                state.isLoadingSummaryOrderMini = false;
            })
            .addCase(updateShipmen.pending, (state) => {
                state.isLoadingUpdateShipmen = true;
            })
            .addCase(updateShipmen.fulfilled, (state) => {
                state.isLoadingUpdateShipmen = false;
            })
            .addCase(updateShipmen.rejected, (state) => {
                state.isLoadingUpdateShipmen = false;
            });
    },
});

export const { setSelectedAddress, setSelectedAddressTax, setLoading, setLoadingSummaryOrder, setLoadingPayment, resetState } = checkoutSlice.actions;
export default checkoutSlice.reducer;
