import React from 'react';
import Chat from './../../components/chats/container/Chat';
import { useDispatch } from 'react-redux';
import { setToken } from '@/store/slices/chatSlice';
import Cookies from 'universal-cookie';

const ChatRoom = () => {

    const dispatch = useDispatch();

    React.useEffect(() => {

        const cookies = new Cookies();
        const _token = cookies.get('token');
 
        dispatch(setToken({
            token: _token,
            user_type: 'buyer'
        }))

        
    }, [])

    return (
        <Chat></Chat>
    );
};

export default ChatRoom;