import { IconButton, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { searchRooms } from '@/store/slices/chatSlice';


interface Props {

}

const SearchChat = (props: Props) => {

    const dispatch = useDispatch();
    const [query, setQuery] = React.useState<string>("");



    React.useMemo(() => {
        const timeOutId = setTimeout(() => {
            dispatch(searchRooms(query))
        }, 500);

        return () => clearTimeout(timeOutId);
    }, [query]);


    return (
        <TextField
            size="small"
            variant="outlined"
            placeholder='ค้นหา'
            fullWidth
            onChange={(e) => setQuery(e.target?.value)}
            style={{
                backgroundColor: '#f5f5f5',
                borderRadius: '20px'
            }}
            InputProps={{
                style: {
                    borderRadius: "20px",
                    height: 40
                },
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}

export default SearchChat