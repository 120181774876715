import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { socketMiddleware } from './../middleware/socket.middleware';

import userReducer from "./slices/userSlice";
import productReducer from "./slices/productSlice";
import chatReducer from "./slices/chatSlice";
import checkoutReducer from "./slices/checkoutSlice";
import articleActivityReducer from "./slices/articleActivitySlice";

const reducer = {
  user: userReducer,
  product: productReducer,
  chat: chatReducer,
  checkout: checkoutReducer,
  articleActivity: articleActivityReducer,
};

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(socketMiddleware)
});


// export type of root state from reducers
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;