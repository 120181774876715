import axios from 'axios';
import Cookies from 'universal-cookie';
import Head from 'next/head';
import Layout from '../components/layout/layout';
import React, { useEffect } from 'react';
import Script from 'next/script';
import { addBadgeTotal } from '@/store/slices/chatSlice';
import { Config, isProduction } from '@/config';
import { config } from '@fortawesome/fontawesome-svg-core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DefaultSeo } from 'next-seo';
import { MetaTag } from 'next-seo/lib/types';
import { Provider, useDispatch } from 'react-redux';
import { removeToken, setToken } from '@/store/slices/userSlice';
import { seoConfig } from '@/config/seo.config';
import { store } from '@/store/store';
import { useRouter } from 'next/router';
import '../styles/scss/main.scss';
import '../styles/chat.css';
import '../public/assets/fonts/stylesheet.css';
import 'react-image-lightbox/style.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import type { AppProps } from 'next/app';

config.autoAddCss = false; // Disable auto addition of FontAwesome CSS globally

const AppWrapper = (props: AppProps) => {
  return (
    <Provider store={store}>
      <App {...props} />
    </Provider>
  );
};

declare global {
  interface Window {
    dataLayer: any[];
    MSStream?: any;
  }
}

function App({ Component, pageProps }: AppProps) {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const authToken = cookies.get('token') || null;

  useEffect(() => {
    if (authToken) {
      dispatch(setToken(authToken));
    } else {
      dispatch(removeToken());
    }

    const getBadgeTotal = async () => {
      if (authToken) {
        try {
          const res = await axios.get(`${Config.apiChatEndpoint}/api/v1/chat/total-badge`, {
            headers: { Authorization: `Bearer ${authToken}` },
          });

          if (res.status === 200) {
            dispatch(
              addBadgeTotal({
                num: res.data?.response?.totalBadge,
                act: 'total',
              })
            );
          }
        } catch (error) {
          console.error('Failed to fetch badge total:', error);
        }
      }
    };

    getBadgeTotal();
  }, [authToken, dispatch]);

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 1920,
      },
    },
    palette: {
      primary: { main: '#DB5A00' },
      secondary: { main: '#994000' },
      steelBlue: { main: '#F9A30B' },
      violet: { main: '#BC00A3' },
      white: { main: '#fff' },
      grey: { main: '#ddd' },
      black: { main: '#000' },
      facebook: { main: '#3b5998' },
      twitter: { main: '#1D9BF0' },
    },
    typography: {
      fontFamily: [
        'NotoSans-Regular, NotoSansThai-Regular',
        'NotoSans-Medium, NotoSansThai-Medium',
      ].join(','),
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
    },
  });

  const router = useRouter();
  // const isProduction = process.env.NEXT_PUBLIC_APP_ENV === 'production';
  const currentUrl = typeof window !== 'undefined' ? window.location.href : "";

  const jsonLdWebSite = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: "https://maaboom.com",
    name: seoConfig.title,
    description: seoConfig.description,
    potentialAction: {
      "@type": "SearchAction",
      target: "https://maaboom.com/search/allsearch?keyword={search_term_string}",
      "query-input": "required name=search_term_string",
    },
    sameAs: seoConfig.socialLinks,
  };

  const jsonLdOnlineStore = {
    "@context": "http://schema.org",
    "@type": "OnlineStore",
    url: "https://maaboom.com",
    logo: "https://cdn.maaboom.com/web/logos.webp",
    foundingDate: "2024-09-01",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://maaboom.com/search/allsearch?keyword={search_term_string}",
      "query-input": "required name=search_term_string",
    },
    name: seoConfig.title,
    description: seoConfig.description,
    address: seoConfig.address,
    sameAs: seoConfig.socialLinks,
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <DefaultSeo
          title={seoConfig.title}
          titleTemplate={seoConfig.titleTemplate}
          defaultTitle={seoConfig.defaultTitle}
          description={seoConfig.description}
          canonical={currentUrl}
          openGraph={{
            ...seoConfig.openGraph,
            url: currentUrl,
            title: seoConfig.title,
          }}
          norobots={true}
          additionalMetaTags={[
            ...seoConfig.additionalMetaTags,
            isProduction ? seoConfig.robots.isProduction : seoConfig.robots.isNotProduction,
          ] as MetaTag[]}
          twitter={seoConfig.twitter}
          facebook={seoConfig.facebook}
        />
        <Layout>
          <Head>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
          </Head>
          {isProduction && router.pathname !== '/chat/seller' && (
            <Script
              id="gtm-init"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-MB4VCMHP');
                `,
              }}
            />
          )}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdWebSite) }}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdOnlineStore) }}
          />
          <Component {...pageProps} />
        </Layout>
      </ThemeProvider>
    </>
  );
}

export default AppWrapper;