import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TagState {
    tagId: number;
}

const initialState: TagState = {
    tagId: 0,
};

const tagSlice = createSlice({
    name: 'articleActivity',
    initialState,
    reducers: {
        setTagId: (state, action: PayloadAction<number>) => {
            state.tagId = action.payload;
        },
    },
});

export const { setTagId } = tagSlice.actions;
export default tagSlice.reducer;
