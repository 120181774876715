import React, { useEffect, useState } from 'react';
import {
    Box,
    ListItem,
    Avatar,
    ListItemText,
    ListItemAvatar,
    Button,
    Typography
} from '@mui/material';
import moment, { Moment } from 'moment';
import InputType from './InputType';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages, getPagination, getRoom, loadMessages } from '@/store/slices/chatSlice';
import Done from '@mui/icons-material/Done';
import DoneAll from '@mui/icons-material/DoneAll';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Link from 'next/link';
import RenderPhotoVideo from './../message/RenderPhotoVideo';
import InfiniteScroll from 'react-infinite-scroll-component';
import Image from "next/image";
import { ImgUrl } from '@/config';

interface Room {
    roomImage: string
    lastMessage: string
    roomId: string
    roomName: string
    participateId: string
    isOnline: string
}


interface Message {
    attachments?: any
    date?: string
    type?: string
    createdAt: Moment
    isOwner: boolean
    message: string
    messageId: string
    read: boolean
    product?: {
        _id: string
        storeId: string
        title: string
        image: string
        price: string
        totalPrice: string
        discount: string
    }
    sticker?: {
        stickerId: string
        stickerUrl: string
    }
    sender: {
        profileImage: string
    }
    faq?: boolean
    contentType: string
}


interface Props {
    responsiveGoBack: (status: boolean) => void;
    // onUpdateMessage: (obj: { roomId: string | undefined, newMessage: string, createdAt: Moment }) => void;
}


const Message = (props: Props) => {

    const dispatch = useDispatch();
    const room = useSelector(getRoom) as Room
    const messages = useSelector(getMessages) as Message[]
    const messagePagination = useSelector(getPagination)

    const [m, setM] = useState(messages);


    const [loadMore, setLoadMore] = useState<boolean>(true);

    const [pagination, setPagination] = useState<{
        skip: number
        limit: number
    }>({
        skip: 0,
        limit: 20
    });

    const messagesEnd = React.useRef<any>(null)
    const [faqs, setFaqs] = React.useState([
        // {
        //     question: 'การคืนสินค้า',
        //     answer: 'test back'
        // }, {
        //     question: 'การรับประกัน',
        //     answer: 'test back 2'
        // }
    ])

    // React.useEffect(() => {
    //     setTimeout(() => {
    //         if (room?.roomId) {
    //             setM(p => [...p, {
    //                 contentType: 'text',
    //                 faq: true,
    //                 createdAt: moment(),
    //                 isOwner: false,
    //                 message: '',
    //                 messageId: '',
    //                 read: true,
    //                 sender: {
    //                     profileImage: room.roomImage
    //                 }
    //             }])
    //         }
    //     }, 1000)
    // }, [])

    React.useEffect(() => {
        setM(messages)
    }, [messages])

    React.useEffect(() => {
        if (room?.roomId && m.length) {
            scrollToBottom();
            setLoadMore(true)
        }
    }, [room])

    const scrollToBottom = () => {
        const scrollHeight = messagesEnd.current.scrollHeight;
        const height = messagesEnd.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        messagesEnd.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    const getFaqAnswer = (index: number) => {
        setM([...m, {
            contentType: 'text',
            createdAt: moment(),
            isOwner: false,
            messageId: '',
            read: true,
            sender: {
                profileImage: room.roomImage
            },
            message: `${faqs[index]?.answer}`
        }])
    }

    const RenderProduct = ({ item }: { item: Message }) => {


        return (
            <Link href={`/product/${item.product?.title}?store=${item.product?.storeId}&id=${item.product?._id}`} target="_blank">

                <ListItem style={{ padding: 0 }}>
                    <ListItemAvatar>
                        <img src={item?.product?.image} style={{ width: 70, height: 70, paddingRight: 11 }}></img>
                    </ListItemAvatar>
                    <ListItemText
                        primary={item?.product?.title}
                        primaryTypographyProps={{
                            style: {
                                fontSize: 13
                            }
                        }}
                        secondary={
                            <>
                                {
                                    item?.product?.discount ?
                                        <div>ส่วนลด {item?.product?.discount}</div>
                                        :
                                        null
                                }
                                <div>
                                    {
                                        item?.product?.discount ?
                                            <div>
                                                <span style={{ textDecoration: 'line-through', marginRight: 10 }}>฿{item?.product?.price}</span>
                                                ฿{item?.product?.totalPrice}
                                            </div>
                                            :
                                            <div>
                                                <span>฿{item?.product?.price}</span>
                                            </div>
                                    }
                                </div>
                            </>
                        }
                        secondaryTypographyProps={{
                            style: {
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: '#24bdbf'
                            }
                        }}
                    />

                </ListItem>
            </Link>
        )
    }

    const loadMoreMessages = React.useCallback(() => {

        if (!messagePagination.next) {
            return;
        }

        const skip = messagePagination.next || pagination.skip

        if (loadMore == true) {
            dispatch(loadMessages({
                ...room,
                skip: skip,
                limit: pagination.limit
            }))
            setLoadMore(false)
            setPagination({
                skip: skip,
                limit: pagination.limit
            })
        }
    }, [room, messagePagination])

    const linkify = (text: string) => {
        var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        return text.replace(urlRegex, function (url) {
            return '<a href="' + url + '" target="_blank">' + url + "</a>";
        });
    }


    const RenderMessage = ({ item }: { item: Message }) => {

        let customClass = 'left-msg';
        if (item.isOwner) {
            customClass = 'right-msg'
        }

        if (['product', 'sticker'].includes(item.contentType)) {
            customClass += ` ${item.contentType}-msg`;
        }

        return (
            <div className={`msg ${customClass}`}>



                {
                    item.isOwner ?
                        null
                        :
                        <div
                            className="msg-img"
                            style={{
                                backgroundImage: `url(${item.sender?.profileImage})`
                            }}
                        ></div>
                }

                <div className="msg-bubble">

                    <div className="msg-text">

                        {
                            item?.contentType == 'product' ?
                                <RenderProduct item={item}></RenderProduct>
                                :
                                null
                        }

                        {
                            item?.contentType == 'sticker' ?
                                <img src={`${item?.sticker?.stickerUrl}`} width={100} height={100} />
                                :
                                null
                        }


                        {
                            item?.contentType == 'photo' ?
                                <RenderPhotoVideo
                                    item={{
                                        contentType: item.contentType,
                                        attachments: item?.attachments
                                    }}></RenderPhotoVideo>
                                :
                                null
                        }

                        {
                            item?.contentType == 'video' ?
                                <RenderPhotoVideo item={{
                                    contentType: item.contentType,
                                    attachments: item?.attachments
                                }}></RenderPhotoVideo>
                                :
                                null
                        }

                        {/* <div>{item?.message}</div> */}
                        <div dangerouslySetInnerHTML={{ __html: linkify(item?.message) }} />

                        {/* {
                            item?.faq === true ?
                                <div className='faq'>
                                    <div style={{ color: 'black' }}>เลือกคำถามด้านล่าง</div>
                                    <hr></hr>
                                    {
                                        faqs.map((faq, index: number) => {
                                            return (
                                                <div key={index} onClick={() => getFaqAnswer(index)}>
                                                    <span>{faq.question}</span>
                                                    <hr></hr>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                :
                                null
                        } */}

                    </div>

                    <div className="msg-info">
                        {/* <div className="msg-info-name">{item.sender.username}</div> */}
                        <div className="msg-info-time">{moment(item.createdAt).format('HH:mm')}</div>
                        {
                            item?.isOwner ?
                                <span>&nbsp;{item.read ? <DoneAll style={{ fontSize: 12 }} /> : <Done style={{ fontSize: 12 }} />}</span>
                                :
                                null
                        }

                    </div>


                </div>
            </div>
        )
    }



    return (
      <Box
        display={!room?.roomId === undefined ? { xs: "none", md: "block" } : {}}
      >  
          <Box borderBottom={1} borderColor="divider">
            <ListItem>
              <Button
                onClick={() => props.responsiveGoBack(true)}
                className="back-button"
                style={{ color: "black" }}
              >
                <ArrowBack></ArrowBack>
              </Button>
              <ListItemAvatar>
                <Avatar src={room?.roomImage}></Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={room?.roomName}
                secondary={room?.isOnline === "1" ? "ออนไลน์" : "ออฟไลน์"}
              />
            </ListItem>
          </Box>
        <Box
          sx={{
            height: (typeof room.roomId =='undefined') ? "100vh" : "270px",
          }}
          
          flex={1}
          p={2}
          style={{ 
            background: "#DEE2E6", borderBottom: "1px solid #DEE2E6",
            position: "relative",
          }}
        >
          {(typeof room.roomId !='undefined') ? (
            <main className="msger-chat">
              <Box
                ref={messagesEnd}
                id="scrollableDiv"
                style={{
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                sx={{ maxHeight: { xs: "220px", md: "270px" } }}
              >                
                <InfiniteScroll
                  dataLength={m.length}
                  next={loadMoreMessages}
                  // next={() => {}}
                  hasMore={loadMore}
                  inverse={true}
                  loader={null}
                  // loader={<div className="loader" key={0}>Loading ...</div>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>You have seen it all</b>
                    </p>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  {m.map((item, index: number) => {
                    return (
                      <>
                        {item?.type == "day" ? (
                          <div
                            key={index}
                            style={{
                              margin: "20px 0px 20px 0px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              style={{
                                background: "white",
                                borderRadius: 18,
                                fontSize: 12,
                                padding: "4px 10px 4px 10px",
                              }}
                            >
                              {moment(item.date).format("DD/MM/YYYY") ==
                              moment().format("DD/MM/YYYY")
                                ? "Today"
                                : moment(item.date).format("DD/MM")}
                            </span>
                          </div>
                        ) : (
                          <RenderMessage
                            item={item}
                            key={index}
                          ></RenderMessage>
                        )}
                      </>
                    );
                  })}
                </InfiniteScroll>
              </Box>
            </main>
          ) : (
            <Box
              sx={{ minHeight: { xs: "230px", md: "300px" } }}
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height:"60vh"               
              }}
            >
                <Image
                  src={`${ImgUrl.pathImg}web/chat_emp.webp`}
                  alt={"chat image wellcome"}
                  width={130}
                  height={110}
                  style={{ borderRadius: "8px" }}
                />
                <Typography className="txt-bold fontSize-20" sx={{ mt: 2 }}>
                  ยินดีต้อนรับสู่ maaboom Chat
                </Typography>
            </Box>
          )}
        </Box>

        {room?.roomId ? (
          <Box
            p={2}
            borderTop={1}
            borderColor="divider"
            display="flex"
            flexDirection="column"
            sx={{ position: "relative" }}
          >
            <InputType room={room}/>
          </Box>
        ) : null}
      </Box>
    );
}

export default Message;