/* eslint-disable @typescript-eslint/restrict-plus-operands */
import axios, { AxiosResponse } from "axios";
import { Config } from "../config";

interface ApiResponse<T> {
  success: boolean;
  data: T;
}

interface ApiResponseOne {
  data: {
    data: string[];
  };
}


export const ProductGroup = async () => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `category/get-category`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        // "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data as any;
  } catch (error) {

  }
};

export const ProductType = async (token: string) => {
  try {
    const data = await axios<ApiResponseOne>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `category/get-category-pet`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProductInfo = async (sku: string, token = '') => {


  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/${sku}/get-product-info`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
  }
};

export const ProductDetail = async (sku: string, token = '') => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/${sku}/get-product-detail`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProductSpecific = async (sku: string, token = '') => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/${sku}/get-product-specific-info`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProductCategoryNoImage = async (idStore: string, token = '') => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-product-category-with-image`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProductFilterSearch = async (keyword: string, token = '') => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-product-filter-search?keyword=${keyword}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProductSameStore = async (idStore: string | string[] | undefined, limit: string, token: string, productType: string) => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/${idStore}/get-product-from-same-store/?limit=${limit}&product_type=${productType}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProducYouMayLike = async (limit: string, token: string, productType: string) => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-product-you-may-like/?limit=${limit}&product_type=${productType}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProducHomeBestSell = async (limit: string, product: string): Promise<ApiResponse<any>> => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-product-home-best-seller?limit=${limit}&product_type=${product}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error as ApiResponse<any>;
    }
    throw error;
  }
};



export const ProducStoreBestSell = async (storeId: string, productType: string) => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `store/${storeId}/get-store-best-seller?product_type=${productType}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProducHomePage = async (limit: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-products?limit=${limit}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    
  }
};

export const ProducHomeRecommend = async (product: string, token: string, limit: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-product-home-recommend?product_type=${product}&product=all&limit=${limit}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ServiceHomeRecommend = async (service: string, token: string) => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-product-home-recommend?product_type=${service}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProductWarranty = async (idStore: string, token = '') => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/${idStore}/get-product-warranty-info`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProductReview = async (idStore: string, limit: string, offset: string, token: string) => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/${idStore}/get-product-review?limit=${limit}&offset=${offset}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProducSearch = async (keyword: string, productType: string, categoryId: any, token: string) => {
  // console.log(Config.apiEndpoint);

  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-product-filter-search?keyword=${keyword}&product_type=${productType}&category_id=${categoryId}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProductSearchNoLimit = async (keywordText: string, productType: string, categoryId: any) => {

  try {
    const data = await axios<ApiResponse<any>>({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-product-filter-search?keyword=${keywordText}&product_type=${productType}&category_id=${categoryId}&limit=1000`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};


export const FavoriteButton = async (productId: string, token: string) => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "put",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/${productId}/favorite`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const UnFavoriteButton = async (productId: string, token: string) => {
  try {
    const data = await axios<ApiResponse<any>>({
      method: "put",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/${productId}/unfavorite`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};


export const GetStoreInProduct = async (storeId: string, token: string, productType: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/${storeId}/get-product-in-store?product_type=${productType}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
}

export const GetStoreInProductLimit = async (storeId: string, token: string, productType: string, limit: number) => {
  try {

    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/${storeId}/get-product-in-store?product_type=${productType}&limit=${limit}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
}


export const GetSearchProduct = async (storeId: string, token: string, keyword: string, product_type: string, category_id: string) => {

  let url = Config.apiEndpoint + `product/get-product-search?product_type=${product_type}&category_id=${category_id === undefined ? 0 : category_id}&store_id=${storeId}&limit=100`;

  if (keyword !== "") {
    url += `&keyword=${encodeURIComponent(keyword)}`;
  }

  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
}
export const GetSearchProductAdvance = async (storeId: string, token: string, keyword: string, brand: string, types: string, taste: string, pet_types: string, amount: string) => {

  let url = Config.apiEndpoint + `product/get-product-adv-filter-search?brand=${brand}&types=${types}&taste=${taste}&pet_types=${pet_types}&amount=${amount}`;

  if (keyword !== "") {
    url += `&keyword=${encodeURIComponent(keyword)}`;
  }

  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
}

export const GetCategoryMainProduct = async () => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `category/get-category-main`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
}
export const GetCategoryMainProductAdvance = async () => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-product-spec`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
}




export const GetSearchService = async (keyword: string, product_type: string, category_id: string) => {
  let url = Config.apiEndpoint + `product/get-product-search-service?keyword=${keyword}&product_type=${product_type}&category_id=${category_id}`;

  if (keyword !== "") {
    url += `&keyword=${encodeURIComponent(keyword)}`;
  }
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
}


export const GetProductServiceTimeSlot = async (token: string, store_id: string, product_id: any, selectedDate: any) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-product-service-timeslot?store_id=${store_id}&product_id=${product_id}&date=${selectedDate}`,
      headers: {
        "accept-language": "th",
        "x-access-token": token,
        Authorization: "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    // console.log(data);

    return data.data;

  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};



export const GetProductServiceTimeSlotHoliday = async (token: string, store_id: string, product_id: any) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-product-service-timeslot-holiday?store_id=${store_id}&product_id=${product_id}`,
      headers: {
        "accept-language": "th",
        "x-access-token": token,
        Authorization: "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const ProductBestSeller = async (bestSellerStatus: string, storeId: string, product_type: string) => {

  bestSellerStatus === "" && "false"

  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-products?bestseller=${bestSellerStatus}&limit=5&store_id=${storeId}&product_type=${product_type}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};

export const ProductSearchAll = async (params: Record<string, any>) => {

  const queryString = new URLSearchParams(
    Object.entries(params).reduce((acc: Record<string, string>, [key, value]) => {
      acc[key] = value === undefined || value === null ? '' : value.toString();
      return acc;
    }, {})
  ).toString();

  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `product/get-products?${queryString}`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    return null
  }
};

export const GetProducts = async () => {
  try {
    const response: AxiosResponse = await axios({
      maxBodyLength: Infinity,
      url: `${Config.apiEndpoint}product/get-products?order=id&rank=DESC&limit=100&offset=0`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
      method: 'get'
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};


