import { createContext, useContext } from 'react';
import { IProfileContext } from '@/interfaces/profile';

export const ProfileContext = createContext<IProfileContext | null>(null);

export const useProfileContext = (): IProfileContext => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfileContext must be used within a ProfileProvider');
  }
  return context;
};
