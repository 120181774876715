import { useTheme, useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';

interface ResponsiveItemsProps {
    xsCount: number;
    smCount: number;
    mdCount: number;
    lgCount: number;
    xlCount: number;
    xxlCount: number;
}

const useResponsiveItems = ({ xsCount, smCount, mdCount, lgCount, xlCount, xxlCount }: ResponsiveItemsProps): number => {
    const theme = useTheme();
    const [itemCount, setItemCount] = useState<number>(0); // ตั้งค่าเริ่มต้นเป็น 0

    // ตรวจสอบขนาดหน้าจอโดยใช้ useMediaQuery
    const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
    const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
    const isLgScreen = useMediaQuery(theme.breakpoints.only('lg'));
    const isXlScreen = useMediaQuery(theme.breakpoints.only('xl'));
    const isXxlScreen = useMediaQuery(theme.breakpoints.only('xxl'));

    useEffect(() => {
        // ตรวจสอบเงื่อนไขทั้งหมดเมื่อโหลดหน้าหรือขนาดหน้าจอเปลี่ยน
        const getItemCount = () => {
            if (window.innerWidth >= 1600) {
                return xxlCount; // หน้าจอ XXL
            } else if (window.innerWidth >= 1200) {
                return xlCount;  // หน้าจอ XL
            } else if (window.innerWidth >= 992) {
                return lgCount;  // หน้าจอใหญ่ (large)
            } else if (window.innerWidth >= 768) {
                return mdCount;  // หน้าจอขนาดกลาง (medium)
            } else if (window.innerWidth >= 576) {
                return smCount;  // หน้าจอเล็ก (small)
            } else if (window.innerWidth < 576) {
                return xsCount;  // หน้าจอ XS (extra small)
            }
            return 0;  // ค่าดีฟอลต์เมื่อไม่ตรงกับเงื่อนไขใด
        };

        // อัปเดต itemCount หลังจากการคำนวณเสร็จสิ้น
        setItemCount(getItemCount());
    }, [isXsScreen, isSmScreen, isMdScreen, isLgScreen, isXlScreen, isXxlScreen, xsCount, smCount, mdCount, lgCount, xlCount, xxlCount]);

    return itemCount; // คืนค่า itemCount เมื่อเงื่อนไขครบ
};

export default useResponsiveItems;
