import React, { useEffect, useState } from 'react';
import {
    Box, Grid
} from '@mui/material';
import Message from './Message';
import SearchChat from './SearchChat';
import Sidebar from './Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { reConnectSocket } from '@/store/slices/chatSlice';
import { useMediaQuery } from 'react-responsive';
interface Props {
}



const ChatRoom = (props: Props) => {       
    const [isGoBack, setIsGoBack] = useState<boolean>(false)
    const dispatch = useDispatch()
    const isMobileScreen = useMediaQuery({
        query: '(max-width: 600px)'
    })
    
    useEffect(() => {
        const handleActivityFalse = () => {
            // console.log('unfocus')
        };

        const handleActivityTrue = () => {
            // console.log('focus')
            dispatch(reConnectSocket)
        };

        window.addEventListener('focus', handleActivityTrue);
        window.addEventListener('blur', handleActivityFalse);

        return () => {
            window.removeEventListener('focus', handleActivityTrue);
            window.removeEventListener('blur', handleActivityFalse);
        };
    }, []);

    const responsiveGoBack = (status: boolean) => {
        setIsGoBack(!isGoBack)
    }
    return (
        <div>
            <Box display="flex" borderColor="divider" sx={{ height: '540px', overflow: "hidden",zIndex:99999 }} >
                <div className='grid-list' style={{ display: !isMobileScreen ? 'block' : isGoBack ? 'none' : 'block' }}>
                    <SearchChat ></SearchChat>
                    <Sidebar responsiveGoBack={responsiveGoBack}></Sidebar>
                </div>
                <div className='grid-chat' style={{ display: !isMobileScreen ? 'block' : isGoBack ? 'block' : 'none' }}>
                    <Message responsiveGoBack={responsiveGoBack}></Message>
                </div>

            </Box>
        </div >
    );
};

export default ChatRoom;