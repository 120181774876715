import React, { useEffect } from 'react'
import moment from 'moment';


interface Props {
  children: any
}
export default function LastSeen(props: Props) {

  const [timeAgo, setTimeAgo] = React.useState<string>("")

  useEffect(() => {
    if (moment(props.children).isValid()) {
      const timeAgo = moment(props.children).fromNow();
      setTimeAgo(timeAgo)
    }
  }, [props.children])


  return <span style={{ fontSize: 10 }}>{timeAgo}</span>
}

