import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import { IDataProfile } from "@/interfaces/profile";
import { ProfileContext } from "@/hooks/profile-context";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import dynamic from "next/dynamic";
import { Container, Fab, Typography, useMediaQuery } from "@mui/material";
import ScrollTop from "../utilities/ScrollTop";
import Footer from "./footer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IndexMap from "../../pages/map/index";
interface Props {
  children: React.ReactNode;
  window?: () => Window;
  callback: (data: any) => void;
}
const NavbarMap = dynamic(() => import("./navbarmap"));

export default function Layout({ children, props, callback }: any) {
  const router = useRouter();
  const { pathname } = router;
  const getUrl = pathname.split("/")[1] as string;
  const [dataProfile, setDataProfile] = useState<IDataProfile | null>(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  // dynamic
  const Nav = dynamic(() => {
    // if (router.pathname === '/map') {
    //   return import('./navbarmap');
    // }

    return import("./navbar");
  });

  if (pathname === "/chat" || pathname === "/chat/seller") {
    return children;
  }

  if (pathname === "/embed/[slug]") {
    return children;
  }

  return (
    <>
      <ProfileContext.Provider value={{ dataProfile, setDataProfile }}>
        <Nav />

        <div id="back-to-top-anchor" />

        {(() => {
          if (getUrl === "account" || getUrl === "signin") {
            return (
              <Container
                maxWidth={false}
                sx={{
                  padding: "0 !important",
                  minHeight: 0,
                }}
              >
                {children}
              </Container>
            );
          } else if (getUrl === "map") {
            return (
              <Container
                maxWidth={false}
                sx={{
                  padding: "0 !important",
                  minHeight: 0,
                  maxWidth: {
                    xs: "100%", // 0-600px
                    // sm: '900px', // 600-960px
                    md: "960px", // 960-1280px
                    lg: "1920px", // 1280-1920px
                    xl: "2500px", // 1920px and up
                  },
                  margin: "0 auto",
                  paddingLeft: {
                    xs: "20px", // 0-600px
                    sm: "0px", // 600-960px
                    md: "10px", // 960-1280px
                    lg: "10px", // 1280-1920px
                    xl: "0px", // 1920px and up
                  },
                  paddingRight: {
                    xs: "20px", // 0-600px
                    sm: "0px", // 600-960px
                    md: "10px", // 960-1280px
                    lg: "10px", // 1280-1920px
                    xl: "0px", // 1920px and up
                  },
                }}
              >
                {children}
              </Container>
            );
          } else if (getUrl === "article" || getUrl === "campaign") {
            return <>{children}</>;
          } else if (
            getUrl === "cart" ||
            getUrl === "checkout" ||
            getUrl === "product"
          ) {
            return (
              <>
                {isMobile ? (
                  <>
                    <Container
                      sx={{
                        maxWidth: {
                          xs: "100%", // 0-600px
                          // sm: '600px', // 600-960px
                          md: "1360px", // 960-1280px
                          lg: "1920px", // 1280-1920px
                          xl: "2560px", // 1920px and up
                        },
                        margin: "0 auto",
                        paddingLeft: {
                          xs: "20px", // 0-600px
                          sm: "20px", // 600-960px
                          md: "50px", // 960-1280px
                          lg: "50px", // 1280-1920px
                          xl: "80px", // 1920px and up
                        },
                        paddingRight: {
                          xs: "20px", // 0-600px
                          sm: "20px", // 600-960px
                          md: "50px", // 960-1280px
                          lg: "50px", // 1280-1920px
                          xl: "80px", // 1920px and up
                        },
                      }}
                    >
                      {children}
                    </Container>
                  </>
                ) : (
                  <>
                    <Container
                      sx={{
                        maxWidth: {
                          xs: "100%", // 0-600px
                          // sm: '600px', // 600-960px
                          md: "1360px", // 960-1280px
                          lg: "1920px", // 1280-1920px
                          xl: "1600px", // 1920px and up
                        },
                        margin: "0 auto",
                        paddingLeft: {
                          xs: "20px", // 0-600px
                          sm: "20px", // 600-960px
                          md: "50px", // 960-1280px
                          lg: "50px", // 1280-1920px
                          xl: "80px", // 1920px and up
                        },
                        paddingRight: {
                          xs: "20px", // 0-600px
                          sm: "20px", // 600-960px
                          md: "50px", // 960-1280px
                          lg: "50px", // 1280-1920px
                          xl: "80px", // 1920px and up
                        },
                      }}
                    >
                      {children}
                    </Container>
                  </>
                )}
              </>
            );
          } else {
            return (
              <Container
                sx={{
                  maxWidth: {
                    xs: "100%", // 0-600px
                    // sm: '800px', // 600-960px
                    md: "1360px", // 960-1280px
                    lg: "1920px", // 1280-1920px
                    xl: "2560px", // 1920px and up
                  },
                  margin: "0 auto",
                  paddingLeft: {
                    xs: "20px", // 0-600px
                    sm: "20px", // 600-960px
                    md: "50px", // 960-1280px
                    lg: "50px", // 1280-1920px
                    xl: "80px", // 1920px and up
                  },
                  paddingRight: {
                    xs: "20px", // 0-600px
                    sm: "20px", // 600-960px
                    md: "50px", // 960-1280px
                    lg: "50px", // 1280-1920px
                    xl: "80px", // 1920px and up
                  },
                }}
              >
                {children}
              </Container>
            );
          }
        })()}

        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top" color="primary">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
        <Footer />
      </ProfileContext.Provider>
    </>
  );
}
