import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { UserData } from "@/models/user.model";
import { RootState } from "@/store/store";
import * as apiCart from "../../api/cart";
import Router from "next/router";
import Discount from '../../components/store/discount';

interface productState {
  price: string;
  discount: string;
  optionStock: string;
  accessToken: string;
  error?: string;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  user?: UserData;
  productId?: number;
  optionId?: string | null;
}
interface SingleProp {
  token: string;
}

export interface CartAction {
  store_id: number
  product_id: number
  option_id: number
  quantity: number
}

export const signUp = createAsyncThunk(
  "user/signup",
  async (credential: SignAction) => {
    const p1 = new Promise<void>((res) =>
      setTimeout(() => res({ result: "success" }), 1000)
    );
    return await p1;
  }
);


export const deleteProduct = createAsyncThunk(
  "product/delete",
  async (credential: CartAction) => {
    alert(credential);
    const response = await apiCart.ProductAddCart(credential);
    return response;
    // console.log(response.result);
    if (response.result == "200") {
      // throw new Error("login failed");
      "Success"
    }
    // store.dispatch(getProducts());
    // Router.push("/login");
  }
);


const initialState: productState = {
  price: "",
  discount: "",
  optionStock: "",
  accessToken: "",
  isAuthenticated: false,
  isAuthenticating: true,
  user: undefined,
  productId: 0,
  optionId: "",
};

const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    optionProduct: (state, action: PayloadAction<productState>) => {
      state.price = action.payload.price
      state.discount = action.payload.discount
      state.optionStock = action.payload.optionStock
      state.productId = action.payload.productId
      state.optionId = action.payload.optionId
    },

    increment: (state, action: PayloadAction<productState>) => {
      const cartItem = state.cartItems.find(
        (el) => el.product.id === action.payload.id
      );
      if (cartItem) cartItem.qty++;
      else {
        state.cartItems.push({
          product: action.payload,
          qty: 1,
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.username = action.payload.result
    });
  },
});

export const { optionProduct } = productSlice.actions;

// export common user selector
export const productSelector = (store: RootState) => store.product;
// console.log(productSelector);

// // export reducer
export default productSlice.reducer;