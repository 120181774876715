import { ImageList, CardMedia } from '@mui/material';
import { Moment } from 'moment';
import React from 'react';
import Image from "next/image";
import Link from 'next/link';
import Lightbox from 'react-image-lightbox';
import ReactPlayer from 'react-player'

interface Props {
    item: {
        attachments: any[]
        contentType: string
    }
}



const RenderPhotoVideo = (props: Props) => {

    const [photoIndex, setPhotoIndex] = React.useState(0)
    const [imageUrl, setImageUrl] = React.useState<string>("");
    const [isOpen, setIsOpen] = React.useState<boolean>(false)

  

    return (
        <>
            <ImageList
                variant="quilted"
                sx={{ width: 'auto', height: 'auto' }}
                cols={props.item.attachments?.length}
            >

                {
                    isOpen && imageUrl && (
                        <Lightbox
                            mainSrc={props.item.attachments[photoIndex]?.fullPath}
                            nextSrc={props.item.attachments[(photoIndex + 1) % props.item.attachments.length]?.fullPath}
                            prevSrc={props.item.attachments[(photoIndex + props.item.attachments.length - 1) % props.item.attachments.length]?.fullPath}
                            onCloseRequest={() => {                              
                                setIsOpen(false)

                            }}
                            onMovePrevRequest={() =>
                                setPhotoIndex((photoIndex + props.item.attachments.length - 1) % props.item.attachments.length)
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % props.item.attachments.length)
                            }
                        />
                    )
                }

                {props.item.attachments?.map((attachment: any, index: number) => (
                    // <ImageListItem key={index} >
                    <>
                        {
                            props.item.contentType == 'video' ?
                                <Link href={attachment?.fullPath} target={'_blank'}>
                                    {/* <Image
                                    alt=""
                                    key={index}
                                    width={100}
                                    height={100}
                                    style={{ borderRadius: 10 }}
                                    src={'/videoplay.png'}
                                    loading="lazy"
                                /> */}
                                    <ReactPlayer url={attachment?.fullPath}
                                        width='100%'
                                        height='100%' controls={true} />

                                </Link>
                                :

                                <Image
                                    onClick={() => {
                                        setImageUrl(`${attachment?.fullPath}`)
                                        setIsOpen(true)
                                    }}
                                    alt=""
                                    key={index}
                                    width={100}
                                    height={100}
                                    style={{ borderRadius: 10, cursor: 'pointer', objectFit: 'cover' }}
                                    src={`${attachment?.fullPath}`}
                                    loading="lazy"
                                />

                        }


                    </>
                    // </ImageListItem>
                ))}
            </ImageList>
        </>
    )
}

export default RenderPhotoVideo